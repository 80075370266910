
import {
  AnalyticsState,
  AnalyticsTypes,
  DashboardAnalyticsState,
  DashboardFilter,
} from "@/store/modules/analytics/analytics.types";
import { FilterType, FilterValue } from "@/types/types";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

type TicketStat = {
  title: string;
  icon: string;
  stat: number;
  color: string;
};

const Analytics = namespace("Analytics");

@Component({
  components: {
    StatsAvatar: () => import("@/components/dashboard/StatsAvatar.vue"),
  },
})
export default class TicketStats extends Vue {
  @Analytics.State(AnalyticsTypes.DASHBOARD_STATUS)
  public dashboardStatus!: AnalyticsState;

  @Analytics.Getter(AnalyticsTypes.CURRENT_DASHBOARD_FILTER)
  public currentDashboardFilter!: FilterType;

  @Analytics.State(AnalyticsTypes.DASHBOARD_ANALYTICS)
  public dashboardAnalytics!: DashboardAnalyticsState;

  @Analytics.Action(AnalyticsTypes.LOAD_DASHBOARD_STATUS)
  public loadDashboardStats!: (filter: DashboardFilter) => void;

  public filterValue: FilterValue = {};

  get stats(): TicketStat[] {
    return [
      {
        title: "New",
        icon: "mdi-email-newsletter",
        stat: this.getByStatus("New"),
        color: "primary",
      },
      {
        title: "InProgress",
        icon: "mdi-progress-clock",
        stat: this.getByStatus("InProgress"),
        color: "#FFB400",
      },
      {
        title: "Resolved",
        icon: "mdi-check",
        stat: this.getByStatus("Resolved"),
        color: "#57C900",
      },
      {
        title: "Closed",
        icon: "mdi-store-check",
        stat: this.getByStatus("Closed"),
        color: "#13B1FE",
      },
    ];
  }

  get percentageClosed(): string {
    return this.dashboardAnalytics.analytics.percentClosed.toFixed(1);
  }

  getByStatus(status: string): number {
    return (
      this.dashboardStatus.analytics.find((x) => x.name == status)?.count || 0
    );
  }

  filterChange(value: FilterValue): void {
    this.filterValue = value;

    this.loadDashboardStats({
      filterBy: value.filterValue?.value,
      force: value.forced,
    });
  }

  get period(): string {
    return this.currentDashboardFilter.value.toLocaleLowerCase();
  }

  mounted(): void {
    this.filterValue.filterValue = this.currentDashboardFilter;
    this.filterChange(this.filterValue);
  }
}
